.Form {
  font-family: CeraPro-Bold,Arial Unicode MS,sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #005551;
  text-rendering: optimizeLegibility;
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: column;
}


.title {
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}
.subtitle {
  text-align: center;
  margin-left: 90px;
  margin-right: 90px;
}

@media print, screen and (min-width: 40em){
h2 {
    line-height: 2.625rem;
    margin-bottom: .875rem;
}}
h2 {
    line-height: 2.375rem;
    margin-bottom: .875rem;
}
@media print, screen and (min-width: 40em){
.h2, h2 {
    font-size: 2.125rem;
}}
.h2, h2 {
    font-size: 1.75rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: .5rem;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bolder;
}

.inputContainer {
  appearance:none;
background-attachment:scroll;
background-clip:border-box;
background-color:white;
background-image:none;
background-origin:padding-box;
background-position-x:0px;
background-position-y:0px;
background-size:auto;
border-bottom-color:rgb(0, 85, 81);
border-bottom-left-radius:2px;
border-bottom-right-radius:0px;
border-bottom-style:none;
border-bottom-width:0px;
border-image-outset:0;
border-image-repeat:stretch;
border-image-slice:100%;
border-image-source:none;
border-image-width:1;
border-left-color:rgb(0, 85, 81);
border-left-style:none;
border-left-width:0px;
border-right-color:rgb(0, 85, 81);
border-right-style:none;
border-right-width:0px;
border-top-color:rgb(0, 85, 81);
border-top-left-radius:2px;
border-top-right-radius:0px;
border-top-style:none;
border-top-width:0px;
box-shadow:rgba(155, 165, 173, 0.35) 0px 7px 32px 0px;
box-sizing:border-box;
color:rgb(0, 85, 81);
cursor:text;
display:block;
font-family:CeraPro-Regular, "Arial Unicode MS", sans-serif;
font-size:16px;
font-stretch:100%;
font-style:normal;
font-variant-caps:normal;
font-variant-east-asian:normal;
font-variant-ligatures:normal;
font-variant-numeric:normal;
font-weight:400;
height:52px;
letter-spacing:normal;
line-height:18.4px;

min-width:0px;
overflow-x:visible;
overflow-y:visible;
padding-bottom:0px;
padding-left:16px;
padding-right:16px;
padding-top:0px;
position:relative;
text-align:start;
text-indent:0px;
text-rendering:auto;
text-shadow:none;
text-size-adjust:100%;
text-transform:none;
white-space:nowrap;
width:400.656px;
word-spacing:0px;
writing-mode:horizontal-tb;
z-index:1;
-webkit-box-direction:normal;
-webkit-box-flex:1;
-webkit-font-smoothing:antialiased;
-webkit-rtl-ordering:logical;
-webkit-border-image:none;
margin: 10px;


}

div {
  border-bottom-width: 0px !important;
}

@media screen and (max-width: 480px){
  div.inputContainer {
    width:90% ;
  }
}

.empty {
}

.button {
  align-items:flex-start;
appearance:none;
background-color:rgb(92, 184, 133);
border-bottom-color:rgba(0, 0, 0, 0);
border-bottom-left-radius:2px;
border-bottom-right-radius:2px;
border-bottom-style:solid;
border-bottom-width:1px;
border-image-outset:0;
border-image-repeat:stretch;
border-image-slice:100%;
border-image-source:none;
border-image-width:1;
border-left-color:rgba(0, 0, 0, 0);
border-left-style:solid;
border-left-width:1px;
border-right-color:rgba(0, 0, 0, 0);
border-right-style:solid;
border-right-width:1px;
border-top-color:rgba(0, 0, 0, 0);
border-top-left-radius:2px;
border-top-right-radius:2px;
border-top-style:solid;
border-top-width:1px;
box-shadow:rgba(155, 165, 173, 0.35) 0px 7px 32px 0px;
box-sizing:border-box;
color:rgb(255, 255, 255);
cursor:pointer;
display:inline-block;
font-family:CeraPro-Bold, "Arial Unicode MS", sans-serif;
font-size:14px;
font-stretch:100%;
font-style:normal;
font-variant-caps:normal;
font-variant-east-asian:normal;
font-variant-ligatures:normal;
font-variant-numeric:normal;
font-weight:400;
height:44px;
letter-spacing:normal;
line-height:14px;
margin-bottom:0px;
margin-left:0px;
margin-right:0px;
margin-top:10px;
outline-color:rgb(255, 255, 255);
outline-style:none;
outline-width:0px;
overflow-x:visible;
overflow-y:visible;
padding-bottom:0px;
padding-left:5px;
padding-right:5px;
padding-top:0px;
text-align:center;
text-indent:0px;
text-rendering:auto;
text-shadow:none;
text-size-adjust:100%;
text-transform:none;
transition-delay:0s, 0s;
transition-duration:0.25s, 0.25s;
transition-property:background-color, color;
transition-timing-function:ease-out, ease-out;
user-select:none;
vertical-align:middle;
white-space:pre;
width:220px;
word-spacing:0px;
writing-mode:horizontal-tb;
-webkit-box-direction:normal;
-webkit-font-smoothing:antialiased;
-webkit-rtl-ordering:logical;
-webkit-border-image:none;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;

}