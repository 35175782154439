.marginTop{	
    margin-top: 0px;	
    margin-right: 0px;	
    margin-left: 0px;	
}	
.marginBottom7{	
    margin-bottom: 7.5pt;	
}	
.fontSize18{	
 font-size: 18px;	
}	
.fontSize15{	
 font-size: 15px;	
}	
.fontSize14{	
 font-size: 14px;	
}	

.lineHeightNormal {	
    line-height: normal;	
}	

.fontFamilyCalibri{	
    font-family: Calibri,sans-serif;	
}	

.bold {	
    font-weight: bold;	
}	

.lineHeight107{	
    line-height: 107%;	
} 