.imprint {	
    font-size: 12px;	
    margin-left: 20px;	
    margin-bottom: 20px;	
    margin-top: 20px;	
    display: flex;	
    flex-direction: row;	
    align-self: flex-start;
  }	
  
  .imprintElement {	
    margin-right: 10px;	
    cursor: pointer;	
    color: teal;
  }	
  
  .overlay{	
    position: fixed;	
    top:0;	
    bottom: 0;	
    left:0;	
    right:0;	
    background-color: black;	
    opacity: 0.8;	
    z-index: 20;
  }	
  .termsOverlay{	
    position: fixed;	
    top:150px;	
    left: 50px;	
    right: 50px;	
    bottom:50px;	
    color: black;	
    background-color: white;	
    overflow: scroll;	
    overflow-x: hidden;	
    padding: 30px;	
    border-radius: 10px;	
    font-size: calc(10px + 1vmin);	
    z-index: 100;
  }	
  
  .closeButton{	
    width:28px;	
    height:28px;	
    background-color:black;	
    position: fixed;;	
    border-radius:6px;	
    box-shadow:2px 2px 4px 0 white;	
    top:160px;	
    right:70px;	
  }	
  
  .closeButton:before,.closeButton:after{	
    content:'';	
    top:0;	
    right:0;	
    position:absolute;	
    width:24px;	
    height:3px;	
    background-color:white;	
    border-radius:2px;	
    top:13px;	
    box-shadow:0 0 2px 0 #ccc;	
  }	
  
  .closeButton:before{	
    -webkit-transform:rotate(45deg);	
    -moz-transform:rotate(45deg);	
    transform:rotate(45deg);	
    left:2px;	
  }	
  .closeButton:after{	
    -webkit-transform:rotate(-45deg);	
    -moz-transform:rotate(-45deg);	
    transform:rotate(-45deg);	
    right:2px;	
  }