.Toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: relative;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  height: 68px;
  padding: 12px;
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);

}
.placeholder {
  height: 100px;
  width: 100%;
}

.logo {
  width: 170px;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 7px solid ;
  border-right: 7px solid ;
  cursor: pointer;
  border-bottom: 7px solid black;
}

.arrow-down {
  cursor: pointer;
  width: 0; 
  height: 0; 
  border-left: 7px solid;
  border-right: 7px solid;
  
  border-top: 7px solid black;
}